<template>
  <el-container class="login-page">
    <el-row class="title" :gutter="20">
      <el-col :span="3" :offset="2">
        <img
          src="https://oss.sokogate.com/image/af82b848785b079390200737ab15cdff.png"
          alt=""
        />
      </el-col>
      <el-col :span="16" :offset="2" class="welcome">
        {{ $t("login.welcome") }}
      </el-col>
      <el-col :span="2">
        <languag-switch />
      </el-col>
    </el-row>
    <el-row class="building" :gutter="20">
      <el-col :span="12" class="login">
        <el-card class="login-card">
          <div slot="header" class="clearfix">
            <span>{{ $t("login.welcome") }}</span>
          </div>
          <sui-form
            ref="form"
            :list="formList"
            :defaultdata="formData"
            @submit="submit"
            submitTitle="login.login"
            :submitWidth="100"
            :loading="formLoading"
          />
          <p class="bd-box">
            <el-button class="toregiater" @click="register" type="text">
              {{ $t("login.account") }}
            </el-button>
            <el-button class="toforget" @click="forgetPassword" type="text">
              {{ $t("login.forget") }}
            </el-button>
          </p>
        </el-card>
      </el-col>
      <el-col :span="12" class="loginbg">
        <img src="https://oss.sokogate.com/static/login_page_bg.png" alt="" />
      </el-col>
    </el-row>
    <el-row class="bottom-list">
      <el-col :span="24">
        <LinkDivider :list="linkList" />
      </el-col>
    </el-row>
    <el-row class="bottom">
      <el-col :span="24"
        >{{ $t("login.copyright") }}{{ $t("login.sokoGates")
        }}<version-no name="Vendor_Web"></version-no
      ></el-col>
    </el-row>
  </el-container>
</template>

<script>
import LanguagSwitch from "@/components/LanguagSwitch";
import LinkDivider from "@/components/LinkDivider";
import SuiForm from "@/components/s-ui/form";
import VersionNo from "@/components/VersionNo";
import { Login, GetMenuList } from "@/utils/api";

export default {
  components: {
    LanguagSwitch,
    LinkDivider,
    SuiForm,
    VersionNo,
  },
  data() {
    return {
      formList: [
        {
          type: "mail",
          name: "email",
          label: "login.email",
          rules: [
            {
              required: true,
              message: "login.email",
              trigger: "blur",
            },
            {
              type: "email",
              message: "login.emails",
              trigger: "blur",
            },
          ],
        },
        {
          type: "password",
          name: "password",
          label: "login.password",
          rules: [
            {
              required: true,
              message: "login.password",
              trigger: "blur",
            },
            { eval: "validatePass", trigger: "blur" },
          ],
        },
      ],
      formData: {
        email: "",
        password: "",
      },
      linkList: [
        {
          title: "dashboard",
        },
        {
          title: "orders",
        },
        {
          title: "commodity",
        },
        {
          title: "shop",
        },
        {
          title: "subscriptions",
        },
        {
          title: "accounts",
        },
        {
          title: "store",
        },
        {
          title: "settings",
        },
        {
          title: "evaluation",
        },
        {
          title: "management",
        },
        {
          title: "personal",
        },
        {
          title: "settings",
        },
        {
          title: "shop",
        },
        {
          title: "decoration",
        },
        {
          title: "picture",
        },
        {
          title: "space",
        },
      ],
      formLoading: false,
    };
  },
  created() {
    console.log(this.$router.options.routes);
  },
  methods: {
    register() {
      this.$router.push("/v2/register");
    },
    forgetPassword() {
      this.$router.push("/v2/forget-password");
    },
    // 登录的表单点击事件
    submit(data) {
      console.log("submit:", data);
      this.formLoading = true;
      // 登录的接口
      Login(data)
        .then(async (res) => {
          console.log("login-res:", res.data.userInfo);
          // 登录成功提示
          this.formLoading = false;
          this.$message({
            showClose: false,
            message: this.$t("login.success"),
            type: "success",
          });
          // 如果有token 确认此用户是否有店铺 如果有跳转到主页没有就注册店铺
          if (res.data.token) {
            localStorage.setItem("vendor_user_token", res.data.token);
            const getMenuListRsp = await GetMenuList({
              id: res.data.userInfo.userId,
            }).catch((err) => {
              console.log("getMenuList-catch:", err);
              this.formLoading = false;
              this.$message({
                showClose: true,
                message: err,
                type: "error",
              });
            });
            //console.log("getMenuListRsp:", getMenuListRsp);
            if (!getMenuListRsp) {
              localStorage.removeItem("vendor_user_token");
              return false;
            } else {
              localStorage.setItem(
                "vendor_user",
                JSON.stringify(res.data.userInfo)
              );
              localStorage.setItem("name", res.data.userInfo.email);
              res.data.userInfo.img &&
                localStorage.setItem("img", res.data.userInfo.img);
              localStorage.setItem(
                "routes",
                JSON.stringify(getMenuListRsp.data)
              );
            }
          }
          // if (res.data.userInfo.store === null) {
          //   this.formLoading = false;
          //   this.$router.push("/shop/resident");
          // } else {
          this.formLoading = false;
          this.$router.push(
            this.$route.query.redirectFrom || { name: "index" }
          );
          // }
        })
        .catch((err) => {
          console.log("login-err:", err.errmsg);
          this.formLoading = false;
          this.$message({
            showClose: false,
            message: `${err.message}`,
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.login-page {
  display: flex;
  flex-direction: column;
  min-width: 1500px;
  max-width: 1500px;
  margin: 0 auto;

  .bd-box {
    display: flex;
    justify-content: space-between;
  }

  .title {
    width: 100%;
    margin: 40px 0 0 100px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .welcome {
      font-size: 25px;
    }
  }
  .building {
    background-color: #23262f;
    width: 100%;
    height: 581px;
    position: relative;
    margin-top: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    .login {
      .login-card {
        position: absolute;
        top: 40px;
        left: 120px;
        width: 430px;
        height: 400px;
        border-radius: 5px;
        .clearfix {
          font-size: 30px;
        }
      }
    }
    .loginbg {
      position: absolute;
      left: 700px;
      top: 30px;
      width: 731px;
      height: 512px;
    }
  }
  .bottom-list {
    margin: 30px 0 0 30px;
    .el-link--inner {
      font-size: 12px;
      color: gray;
    }
  }
  .bottom {
    margin: 10px 0 0 30px;
    font-size: 12px;
    color: gray;
  }
}
</style>